<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="180px" :model="formLabelAlign">
        <el-row>
          <!-- <el-col :span="12"> -->
          <el-form-item
            label="名称："
            prop="name"
            :rules="[{ required: true, message: '请输入名称' }]"
          >
            <el-input
              placeholder="请输入名称"
              size="mini"
              class="form-inp"
              v-model="formLabelAlign.name"
            ></el-input>
          </el-form-item>
          <!-- </el-col> -->

          <!-- <el-col :span="12"> -->
          <el-form-item label="人员补助：">
            <el-input
              placeholder="人员补助"
              size="mini"
              class="form-inp"
              v-model="formLabelAlign.money"
            ></el-input>
          </el-form-item>
          <!-- </el-col> -->

          <!-- <el-col :span="12"> -->
          <el-form-item
            label="开始时间："
            prop="begin_time"
            :rules="[{ required: true, message: '请选择时间' }]"
          >
            <el-time-picker
              format="HH:mm"
              v-model="formLabelAlign.begin_time"
              value-format="HH:mm:ss"
              placeholder="开始时间"
            >
            </el-time-picker>
          </el-form-item>
          <!-- </el-col> -->

          <!-- <el-col :span="12">  -->
          <el-form-item
            label="结束时间："
            prop="end_time"
            :rules="[{ required: true, message: '请选择时间' }]"
          >
            <el-time-picker
              format="HH:mm"
              v-model="formLabelAlign.end_time"
              value-format="HH:mm:ss"
              placeholder="结束时间"
            >
            </el-time-picker>
          </el-form-item>
          <!-- </el-col> -->
          <div style="display: flex">
            <el-form-item
              label="有效打卡时间："
              prop="exp_begintime"
              :rules="[{ required: true, message: '请选择时间' }]"
            >
              <el-time-select
                placeholder="起始时间"
                v-model="formLabelAlign.exp_begintime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '23:55',
                }"
              >
              </el-time-select>
            </el-form-item>
            <div>-</div>
            <el-form-item
              label-width="0"
              prop="exp_begintime"
              :rules="[{ required: true, message: '请选择结束时间' }]"
            >
              <el-time-select
                placeholder="结束时间"
                v-model="formLabelAlign.exp_endtime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '23:55',
                  minTime: formLabelAlign.exp_begintime,
                }"
              >
              </el-time-select>
            </el-form-item>
          </div>
          <!-- <el-col :span="12">
            <el-form-item label="星期一带班人：">
              <el-input
                placeholder="请输入星期一带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.monday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期一值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期一值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.monday_executor"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <!-- <el-col :span="12">
            <el-form-item label="星期二带班人：">
              <el-input
                placeholder="请输入星期二带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.tuesday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期二值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期二值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.tuesday_executor"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="星期三带班人：">
              <el-input
                placeholder="请输入星期三带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.wednesday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期三值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期三值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.wednesday_executor"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="星期四带班人：">
              <el-input
                placeholder="请输入星期四带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.thursday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期四值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期四值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.thursday_executor"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="星期五带班人：">
              <el-input
                placeholder="请输入星期五带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.friday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期五值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期日值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.friday_executor"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="星期六带班人：">
              <el-input
                placeholder="请输入星期日带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.saturday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期六值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期六值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.saturday_executor"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="星期日带班人：">
              <el-input
                placeholder="请输入星期日带班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.sunday_leader"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星期日值班人：">
              <el-input
                type="textarea"
                placeholder="请输入星期日值班人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.sunday_executor"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { dutysaveInfo, dutysave } from "../../assets/request/api";
export default {
  name: "student_redacy",
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      formLabelAlign: {
        name: "",
        begin_time: "",
        end_time: "",
        money: "",
        exp_begintime: "",
        exp_endtime: "",
        // monday_leader: "",
        // monday_executor: "",
        // tuesday_leader: "",
        // tuesday_executor: "",
        // wednesday_leader: "",
        // wednesday_executor: "",
        // thursday_leader: "",
        // thursday_executor: "",
        // friday_leader: "",
        // friday_executor: "",
        // saturday_leader: "",
        // saturday_executor: "",
        // sunday_leader: "",
        // sunday_executor: "",
      },
      status_list: [],
      gender_list: [],
      class_Data: [],
      class_value: "",

      // //时间选择
      // end_time:[]
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    way_time(a) {},
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      dutysaveInfo(froms).then((res) => {
        let { info, status_list, gender_list } = res.data;
        this.status_list = status_list;
        this.gender_list = gender_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            name,
            money,
            begin_time,
            end_time,
            exp_begintime,
            exp_endtime,
            // truename,
            // id_card,
            // gender,
            // address,
            // home_address,
            // phone,
            // em_contact1,
            // em_contact1_tel,
            // em_contact2,
            // em_contact2_tel,
            // desc,
            // status,
            // class_id,
          } = info;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.money = money;
          formLabelAlign.begin_time = begin_time;
          formLabelAlign.end_time = end_time;

          formLabelAlign.exp_begintime = exp_begintime;
          formLabelAlign.exp_endtime = exp_endtime;

          // formLabelAlign.id_card = id_card;
          // formLabelAlign.gender = gender;
          // formLabelAlign.address = address;
          // formLabelAlign.phone = phone;
          // formLabelAlign.em_contact1 = em_contact1;
          // formLabelAlign.em_contact1_tel = em_contact1_tel;
          // formLabelAlign.em_contact2 = em_contact2;
          // formLabelAlign.desc = desc;
          // formLabelAlign.em_contact2_tel = em_contact2_tel;
          // formLabelAlign.status = status;
          // formLabelAlign.class_id = Number(class_id);
          // formLabelAlign.home_address = home_address;
        }
      });
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign } = this;

          let From = JSON.parse(JSON.stringify(formLabelAlign));

          dutysave(From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>