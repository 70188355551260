var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"from_outer"},[_c('div',[_c('el-form',{ref:"formName",attrs:{"label-width":"180px","model":_vm.formLabelAlign}},[_c('el-row',[_c('el-form-item',{attrs:{"label":"名称：","prop":"name","rules":[{ required: true, message: '请输入名称' }]}},[_c('el-input',{staticClass:"form-inp",attrs:{"placeholder":"请输入名称","size":"mini"},model:{value:(_vm.formLabelAlign.name),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "name", $$v)},expression:"formLabelAlign.name"}})],1),_c('el-form-item',{attrs:{"label":"人员补助："}},[_c('el-input',{staticClass:"form-inp",attrs:{"placeholder":"人员补助","size":"mini"},model:{value:(_vm.formLabelAlign.money),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "money", $$v)},expression:"formLabelAlign.money"}})],1),_c('el-form-item',{attrs:{"label":"开始时间：","prop":"begin_time","rules":[{ required: true, message: '请选择时间' }]}},[_c('el-time-picker',{attrs:{"format":"HH:mm","value-format":"HH:mm:ss","placeholder":"开始时间"},model:{value:(_vm.formLabelAlign.begin_time),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "begin_time", $$v)},expression:"formLabelAlign.begin_time"}})],1),_c('el-form-item',{attrs:{"label":"结束时间：","prop":"end_time","rules":[{ required: true, message: '请选择时间' }]}},[_c('el-time-picker',{attrs:{"format":"HH:mm","value-format":"HH:mm:ss","placeholder":"结束时间"},model:{value:(_vm.formLabelAlign.end_time),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "end_time", $$v)},expression:"formLabelAlign.end_time"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('el-form-item',{attrs:{"label":"有效打卡时间：","prop":"exp_begintime","rules":[{ required: true, message: '请选择时间' }]}},[_c('el-time-select',{attrs:{"placeholder":"起始时间","picker-options":{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }},model:{value:(_vm.formLabelAlign.exp_begintime),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "exp_begintime", $$v)},expression:"formLabelAlign.exp_begintime"}})],1),_c('div',[_vm._v("-")]),_c('el-form-item',{attrs:{"label-width":"0","prop":"exp_begintime","rules":[{ required: true, message: '请选择结束时间' }]}},[_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
                start: '00:00',
                step: '00:05',
                end: '23:55',
                minTime: _vm.formLabelAlign.exp_begintime,
              }},model:{value:(_vm.formLabelAlign.exp_endtime),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "exp_endtime", $$v)},expression:"formLabelAlign.exp_endtime"}})],1)],1)],1)],1),_c('div',{staticStyle:{"text-align":"center","margin":"20px 0"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.on_preserve('formName')}}},[_vm._v(_vm._s(_vm.type_name))]),_c('el-button',{on:{"click":_vm.route_go}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }